@import url('./index.css');

@media (min-width: 601px) {
    .CristalImgBigButton {
        width: 26px;
    }
    .HomeSocieteTitle {
        font-size: var(--font-size-h3) !important;
    }
}

/* Icône des alvéoles*/
.CristalImgBigButton {
    height: 24px;
    width: 24px;
}

/* Texte des alvéoles*/
.CristalTextBigButton {
    color: #ffffff;
    font-size: var(--font-size-label) !important;
    max-width: 105px;
    word-wrap: break-word;
}

